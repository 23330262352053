import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
const language = localStorage.getItem("i18nextLng") || "de";
i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: language ? language : "de",
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
