import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  securityManagementAssets: null,
  individualProcessAssetsList: null,
  assetQuestionList: null,
  assetId: "",
  assetName: "",
  processName: "",
  userList: [],
  loading: false,
  enableEvaluateBtn: false,
  errorMsg: "",
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
    setAssetId(state, action) {
      state.assetId = action.payload;
    },
    setAssetName(state, action) {
      state.assetName = action.payload;
    },
    setIndividualProcessName(state, action) {
      state.processName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Security Management Asset -- */
      .addCase(securityManagementAssets.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(securityManagementAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.securityManagementAssets = action.payload["results"];
      })
      .addCase(securityManagementAssets.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get individual process Asset list -- */
      .addCase(getIndividualProcessAssets.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getIndividualProcessAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.individualProcessAssetsList = action.payload["results"];
      })
      .addCase(getIndividualProcessAssets.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get individual Asset questions list -- */
      .addCase(getIndividualAssetQuestions.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getIndividualAssetQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.assetQuestionList = action.payload["results"];
      })
      .addCase(getIndividualAssetQuestions.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Answers patch response -- */
      .addCase(submitAnswer.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        state.errorMsg = "";
        state.loading = false;
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Evaluate button enable/disable -- */
      .addCase(evaluateBtnEnable.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(evaluateBtnEnable.fulfilled, (state, action) => {
        state.errorMsg = "";
        state.loading = false;
        state.enableEvaluateBtn = action.payload.evaluate;
      })
      .addCase(evaluateBtnEnable.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Evaluate -- */
      .addCase(evaluate.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(evaluate.fulfilled, (state, action) => {
        state.errorMsg = "";
        state.loading = false;
      })
      .addCase(evaluate.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Users -- */
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.userList = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Filter -- */
      .addCase(filterQuestionsList.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(filterQuestionsList.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.assetQuestionList = action.payload["results"];
      })
      .addCase(filterQuestionsList.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Security Management Asset -- */
export const securityManagementAssets = createAsyncThunk(
  "assessment/SecurityManagementAsset",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/security-assessments/?limit=100`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Individual process Asset list -- */
export const getIndividualProcessAssets = createAsyncThunk(
  "assessment/getIndividualProcessAssets",
  async (processId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/processes/${processId}/assessments/?limit=500`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Individual Asset question list -- */
export const getIndividualAssetQuestions = createAsyncThunk(
  "assessment/getIndividualAssetQuestions",
  async (assetId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/assets/${assetId}/questions/?limit=200`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Question: Answer patch call  -- */
export const submitAnswer = createAsyncThunk(
  "assessment/submitAnswer",
  async (answer, { rejectWithValue, dispatch }) => {
    const { assetId, modifiedAnswer } = answer;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/questions/${modifiedAnswer.id}/`,
        modifiedAnswer,
        {
          withCredentials: true,
        },
      );
      const data = await response.data;

      dispatch(getIndividualAssetQuestions(assetId));
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Evaluate button enable/disable -- */
export const evaluateBtnEnable = createAsyncThunk(
  "assessment/evaluateBtnEnable",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/check/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Evaluate -- */
export const evaluate = createAsyncThunk(
  "assessment/evaluate",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/risks/evaluate/`,
        {},
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get all Users -- */
export const getUsers = createAsyncThunk(
  "assessment/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/users/?limit=500`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Filtering -- */
export const filterQuestionsList = createAsyncThunk(
  "assessment/filterQuestionsList",
  async (searchItem, { rejectWithValue, dispatch }) => {
    const { assetId, query, type } = searchItem;

    let url;
    if (type === "filterByTeam") {
      url = `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/assets/${assetId}/questions/?assignedTo=${query}&limit=50`;
    } else if (type === "filterByAnswer") {
      url = `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/assets/${assetId}/questions/?answer=${query}&limit=50`;
    }

    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const {
  resetState,
  setAssetId,
  setAssetName,
  setIndividualProcessName,
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
