import React from "react";

const AssetIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="asset-icon"
  >
    <g clipPath="url(#clip0_5716_616)">
      <path
        d="M11.2749 6.95972C10.7979 7.29979 10.2141 7.49984 9.58366 7.49984C7.97283 7.49984 6.66699 6.194 6.66699 4.58317C6.66699 2.97234 7.97283 1.6665 9.58366 1.6665C10.6278 1.6665 11.5438 2.21519 12.0591 3.03996M5.00033 16.7391H7.17557C7.45918 16.7391 7.74106 16.7729 8.016 16.8404L10.3144 17.3989C10.8131 17.5204 11.3326 17.5322 11.8366 17.4343L14.3778 16.9399C15.0491 16.8092 15.6666 16.4877 16.1506 16.0169L17.9486 14.2679C18.462 13.7693 18.462 12.9602 17.9486 12.4607C17.4863 12.011 16.7543 11.9604 16.2313 12.3418L14.1358 13.8705C13.8357 14.0899 13.4706 14.208 13.095 14.208H11.0716L12.3595 14.208C13.0855 14.208 13.6735 13.6359 13.6735 12.9298V12.6741C13.6735 12.0878 13.2633 11.5765 12.6787 11.4347L10.6908 10.9513C10.3673 10.8728 10.036 10.8332 9.70294 10.8332C8.89894 10.8332 7.44357 11.4989 7.44357 11.4989L5.00033 12.5206M16.667 5.4165C16.667 7.02733 15.3612 8.33317 13.7503 8.33317C12.1395 8.33317 10.8337 7.02733 10.8337 5.4165C10.8337 3.80567 12.1395 2.49984 13.7503 2.49984C15.3612 2.49984 16.667 3.80567 16.667 5.4165ZM1.66699 12.1665L1.66699 16.9998C1.66699 17.4665 1.66699 17.6999 1.75782 17.8782C1.83771 18.035 1.9652 18.1624 2.122 18.2423C2.30026 18.3332 2.53361 18.3332 3.00033 18.3332H3.66699C4.1337 18.3332 4.36706 18.3332 4.54532 18.2423C4.70212 18.1624 4.8296 18.035 4.9095 17.8782C5.00033 17.6999 5.00033 17.4665 5.00033 16.9998V12.1665C5.00033 11.6998 5.00033 11.4664 4.9095 11.2882C4.8296 11.1314 4.70212 11.0039 4.54532 10.924C4.36706 10.8332 4.1337 10.8332 3.66699 10.8332L3.00033 10.8332C2.53362 10.8332 2.30026 10.8332 2.122 10.924C1.9652 11.0039 1.83771 11.1314 1.75782 11.2882C1.66699 11.4664 1.66699 11.6998 1.66699 12.1665Z"
        stroke={"#667085"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5716_616">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AssetIcon;
