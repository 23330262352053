import { gridClasses } from "@mui/x-data-grid";
import GridViewIcon from "@mui/icons-material/GridView";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ListIcon from "@mui/icons-material/List";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AssetIcon from "../components/svg/AssetIcon";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import StoreIcon from "@mui/icons-material/Store";
import theme from "../themeConfig";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export const APP_NAME = "Cybervize";

// Constants for OTP configuration
export const OTP_ATTEMPTS = 10;
export const COOLDOWN_PERIOD = 1800; // 30 minutes

// sidebar desktop width
export const DESKTOP_WIDTH = 1024;

// Regular expressions
export const EMAIL_REGEX = /\S+@\S+\.\S+/;

// URLs
export const CYBERVIZE_IMPRINT_URL = "https://cybervize.net/imprint";
export const CYBERVIZE_PRIVACY_POLICY_URL =
  "https://cybervize.net/privacy-policy";
export const CYBERVIZE_TERMS_POLICY_URL =
  "https://cybervize.net/terms-and-conditions";

// Common DataGrid styles and props
export const COMMON_DATA_GRID_STYLES = {
  [`& .${gridClasses.columnHeaders}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.whiteCustom.main,
  },
};

export const COMMON_DATA_GRID_PROPS = {
  hideFooter: true,
  initialState: {
    pagination: {
      paginationModel: { page: 0, pageSize: 20 },
    },
  },
};

// options for the risk measure, whether the measure is implemented, open, or in progress
export const RISK_ACTION_OPTIONS = {
  implemented: {
    key: "implemented",
    label: "Implemented",
    color: theme.palette.primary.main,
  },
  inImplementation: {
    key: "inImplementation",
    label: "In Implementation",
    color: theme.palette.yellowishOrange.main,
  },
  open: {
    key: "open",
    label: "Open",
    color: "#44444470",
  },
};

// button colors for different states of the risk measure
export const RISK_MEASURES_COLOR = {
  high: theme.palette.torchRed.main,
  medium: theme.palette.yellowishOrange.main,
  low: theme.palette.primary.main,
};

// button colors for different states of the risk measure, for example, cost, effectiveness, and risk level
export const RISK_MEASURES_VALUE = {
  high: 3,
  medium: 2,
  low: 1,
};

// data grid styles for risk measures
export const RISK_MEASURES_DG_STYLES = {
  "& .name-cell": {
    fontWeight: 700,
  },
  [`& .menu-visible-header .${gridClasses.iconButtonContainer}, & .menu-visible-header .${gridClasses.menuIcon}`]:
    {
      visibility: "visible !important",
      width: "auto !important",
    },
  [`& .menu-visible-header .${gridClasses.sortIcon}`]: {
    opacity: "1 !important",
  },
  [`&.${gridClasses["root--densityCompact"]} .${gridClasses.cell}`]: {
    py: "8px",
  },
  [`&.${gridClasses["root--densityStandard"]} .${gridClasses.cell}`]: {
    py: "15px",
  },
  [`&.${gridClasses["root--densityComfortable"]} .${gridClasses.cell}`]: {
    py: "22px",
  },
};

export const ACCEPTED_FILETYPES = {
  "image/png": [".png", ".heic", ".jpeg", ".jpg"],
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/msword": [".doc"],
  "text/plain": [".txt"],
};

export const TASK_SEVERITY = [
  {
    key: "High",
    value: "High",
  },
  {
    key: "Medium",
    value: "Medium",
  },
  {
    key: "Low",
    value: "Low",
  },
];

export const TASK_STATUS = [
  {
    key: "open",
    value: "Open",
    isAdmin: false,
  },
  {
    key: "in_progress",
    value: "In Progress",
  },
  {
    key: "in_review",
    value: "In Review",
  },
  {
    key: "closed",
    value: "Closed",
  },
];

export const TASK_STATUS_KEYS = {
  open: "Open",
  in_progress: "In Progress",
  in_review: "In Review",
  approved: "Approved",
  closed: "Closed",
};

export const INCIDENT_STATUS = [
  {
    key: "Open",
    value: "Open",
  },
  {
    key: "Reviewed",
    value: "Reviewed",
  },
  {
    key: "Ignored",
    value: "Ignored",
  },
  {
    key: "Closed",
    value: "Closed",
  },
];

export const USER_ROLES = {
  ADMIN: 1,
  USER: 2,
};

export const DASHBOARD_MENU_ITEMS = [
  {
    to: "home",
    Icon: GridViewIcon,
    name: "dashboard.menu.dashboardHome",
  },
  {
    to: "incidents",
    Icon: InfoOutlinedIcon,
    name: "dashboard.menu.incidents",
  },
  {
    to: "tasks",
    Icon: ListIcon,
    name: "dashboard.menu.tasks",
  },
  {
    to: "risks",
    Icon: ShieldOutlinedIcon,
    name: "dashboard.menu.risks",
  },
  {
    to: "measures",
    Icon: EqualizerIcon,
    name: "dashboard.menu.measures",
  },
  {
    to: "assets",
    Icon: AssetIcon,
    name: "dashboard.menu.assets",
  },
  {
    to: "assessment",
    Icon: ContentPasteIcon,
    name: "dashboard.menu.assessment",
  },
  {
    to: "compliance",
    Icon: GppGoodOutlinedIcon,
    name: "dashboard.menu.compliance",
  },
  {
    to: "settings",
    Icon: SettingsOutlinedIcon,
    name: "dashboard.menu.settings",
  },
  {
    to: "store",
    Icon: StoreIcon,
    name: "dashboard.menu.store",
  },
];

export const INITIAL_DASHBOARD_TABS_ENABLED = [
  "assets",
  "assessment",
  "settings",
];

// This will be removed once we have the complete features ready.
export const WORKING_DASHBOARD_TABS = [
  "incidents",
  "tasks",
  "risks",
  "assets",
  "assessment",
  "settings",
];

// Risk priority options - step 5 business risk criteria of user onboarding
export const RISK_PRIORITY_OPTIONS = [
  {
    label: "implementationSupport.businessCriteria.table.tableHeader2",
    value: 1,
  },
  {
    label: "implementationSupport.businessCriteria.table.tableHeader3",
    value: 3,
  },
  {
    label: "implementationSupport.businessCriteria.table.tableHeader4",
    value: 5,
  },
  {
    label: "implementationSupport.businessCriteria.table.tableHeader5",
    value: 7,
  },
];

// task repeat frequency options
export const RECURRING_TASK_OPTIONS = [
  {
    id: "DAILY",
    label: "commonPlaceholder.recurring_task_options.daily",
    alternativeLabel: "commonPlaceholder.recurring_task_options.day",
  },
  {
    id: "WEEKLY",
    label: "commonPlaceholder.recurring_task_options.weekly",
    alternativeLabel: "commonPlaceholder.recurring_task_options.week",
  },
  {
    id: "MONTHLY",
    label: "commonPlaceholder.recurring_task_options.monthly",
    alternativeLabel: "commonPlaceholder.recurring_task_options.month",
  },
  {
    id: "YEARLY",
    label: "commonPlaceholder.recurring_task_options.yearly",
    alternativeLabel: "commonPlaceholder.recurring_task_options.year",
  },
];

// classes to display the onboarding tour corresponding to each step
export const ONBOARDING_TOUR_CLASSES = [
  "about-company-tour",
  "search-industry-tour",
  "complaince-tour",
  "business-process-tour",
  "impact-criteria-tour",
  "drag-drop-row",
  "process-evaluation-tour",
  "critical-business-process",
];

export const MUI_GRID_SCROLLER_CLASS = ".MuiDataGrid-virtualScroller";
