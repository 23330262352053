import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Loader from "../common/loader/Loader";
import { getCompanyPreviousDetails } from "../features/user-onboarding/userOnboardingSlice";

const WithoutAuthRedirect = ({ element }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const isReloading = useSelector((state) => state.login.isReloading);
  const companyDetails = useSelector(
    (state) => state.onboarding.companyDetails,
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCompanyPreviousDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && Object.keys(companyDetails || {})?.length) {
      if (companyDetails?.subscription != null) {
        if (companyDetails?.isComplete) {
          if (!location.pathname.startsWith("/dashboard")) {
            // navigate("/dashboard/home");
            //Below navigate will be removed once dashboard home is completed
            navigate("/dashboard/incidents");
          }
        } else if (!location.pathname.startsWith("/user-onboarding-process")) {
          navigate("/user-onboarding-process");
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const paymentSuccess = urlParams.get("payment");
        if (paymentSuccess === "success") {
          navigate("/user-onboarding-process?payment=success");
        } else {
          navigate("/choose-number-of-employees");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, companyDetails]);

  // If loading, you can render a loading spinner or message
  if (isReloading) {
    return <Loader />;
  }

  // If logged in, redirect to dashboard - TODO: remove it later if no ripple effects are found
  //   if (isLoggedIn) {
  //     return <Navigate to="/dashboard/home" replace />;
  //   }

  // If not logged in, render the component
  return element;
};

export default WithoutAuthRedirect;
