import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const language = localStorage.getItem("i18nextLng") || "de";

const initialState = {
  loading: false,
  errorMsg: "",
  userDetails: {},
  companyDetails: {},
  userList: [],
  currentPage: 1, // Start from page 1
  totalPages: 0,
  language: language,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return { ...initialState, language: state.language };
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setTotalPages(state, action) {
      state.totalPages = action.payload;
    },
    getAllUsersSuccess(state, action) {
      state.loading = false;
      state.errorMsg = "";

      // Append new data to existing user list
      state.userList = [...state.userList, ...action.payload.results];

      // Update total pages based on count and number of items per page
      state.totalPages = Math.ceil(action.payload.count / 5);
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- User Information -- */
      .addCase(userDetails.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        const language = localStorage.getItem("i18nextLng") || "de";
        state.loading = false;
        state.errorMsg = "";
        state.userDetails = action.payload;
        state.language = language;
      })
      .addCase(userDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Edit user information -- */
      .addCase(editUserDetails.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(editUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(editUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Company Information -- */
      .addCase(companyDetails.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(companyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";

        state.companyDetails = action.payload;
      })
      .addCase(companyDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Edit company information -- */
      .addCase(editCompanyDetails.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(editCompanyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(editCompanyDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get all users -- */
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";

        // UserList pagination logic
        if (state.currentPage === 1) {
          state.userList = action.payload;
        } else {
          state.userList = [...state.userList, ...action.payload];
        }

        // Update total pages based on count and number of items per page
        state.totalPages = Math.ceil(action.payload.count / 5);
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Edit user -- */
      .addCase(editUser.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Add user -- */
      .addCase(addUser.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Change password -- */
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- payment -- */
      .addCase(payment.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(payment.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(payment.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Fetch user information -- */
export const userDetails = createAsyncThunk(
  "setting/userDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/personal-info/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Edit user information -- */
export const editUserDetails = createAsyncThunk(
  "setting/editUserDetails",
  async (userDetails, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/personal-info/`,
        userDetails,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Fetch company information -- */
export const companyDetails = createAsyncThunk(
  "setting/companyDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/company-profile/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Edit company information -- */
export const editCompanyDetails = createAsyncThunk(
  "setting/editCompanyDetails",
  async (companyDetails, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/company-profile/`,
        companyDetails,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get all Users -- */
export const getAllUsers = createAsyncThunk(
  "setting/getAllUsers",
  async (_, { rejectWithValue, getState }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const { currentPage } = getState().setting;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/users/?page=${currentPage}`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Edit user -- */
export const editUser = createAsyncThunk(
  "setting/editUser",
  async (userInfo, { rejectWithValue }) => {
    const { id, ...userInfoWithoutId } = userInfo;

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/users/${userInfo.id}/`,
        userInfoWithoutId,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Add user -- */
export const addUser = createAsyncThunk(
  "setting/addUser",
  async (userInfo, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/add-new-user/`,
        userInfo,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Login & Security -- */
export const changePassword = createAsyncThunk(
  "setting/changePassword",
  async (passwordInfo, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/settings/change-password/`,
        passwordInfo,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Payment -- */
export const payment = createAsyncThunk(
  "setting/payment",
  async (passwordInfo, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/payments/customer-portal/`,
        passwordInfo,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState, setCurrentPage, getAllUsersSuccess, setLanguage } =
  settingSlice.actions;

export default settingSlice.reducer;
