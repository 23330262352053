import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by error boundary:", error, errorInfo);
  }

  handleGoBack = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className=" flex justify-center h-screen " data-testid="error">
          <div className=" mx-auto mt-24">
            <h1 className=" text-2xl">
              An Error encountered while processing.
            </h1>
            <button
              className="bg-primary mt-2 py-2 px-2 mr-2 w-24 text-white rounded"
              onClick={this.handleGoBack}
            >
              Go back
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function AppErrorBoundary({ children }) {
  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default AppErrorBoundary;
