import { Alert, Snackbar } from "@mui/material";

const BACKGROUND_COLOR = {
  success: "primary.main",
  error: "error_custom.main",
};

const SnackbarAlert = ({
  open,
  handleClose,
  severity = "success",
  message,
  autoHideDuration,
  anchorOrigin,
}) => {
  if (!message) return null;
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration || 6000}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || { vertical: "bottom", horizontal: "center" }
        }
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{
            backgroundColor: BACKGROUND_COLOR[severity],
            width: "100%",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarAlert;
