/* eslint-disable no-undef */
import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  topRisk: null,
  assetList: null,
  assetDetailData: null,
  errorMsg: "",
};

const riskSlice = createSlice({
  name: "risk",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Top Risks -- */
      .addCase(topRisks.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(topRisks.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.topRisk = action.payload;
      })
      .addCase(topRisks.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Selected risk related assets -- */
      .addCase(allAssets.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(allAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.assetList = action.payload.results;
      })
      .addCase(allAssets.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Individual asset details -- */
      .addCase(assetDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(assetDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.assetDetailData = action.payload;
      })
      .addCase(assetDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Top Risks -- */
export const topRisks = createAsyncThunk(
  "risks/topRisks",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/risks/`,

        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- All assets based on selected risk  -- */
export const allAssets = createAsyncThunk(
  "risks/allAssets",
  async (riskID, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/risks/${riskID}/assets/?limit=1000`,

        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Individual asset details  -- */
export const assetDetail = createAsyncThunk(
  "risks/assetDetail",
  async (assetID, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/risks/assets/${assetID}/`,

        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = riskSlice.actions;
export default riskSlice.reducer;
