import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  assetProcesses: [],
  assets: {},
  defaultAssets: {},
  defaultAssetsLoading: false,
  defaultSubAssets: [],
  defaultSubAssetsLoading: false,
  loading: false,
  isAssetsLoading: false,
  errorMsg: "",
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    resetState: (state, action) => {
      return initialState;
    },
    resetAssets: (state) => {
      state.assets = {};
    },
    resetDefaultAssets: (state) => {
      state.defaultAssets = {};
      state.defaultSubAssets = [];
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- get General Business Process with Top-3 processes -- */
      .addCase(getAssetProcesses.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getAssetProcesses.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.assetProcesses = action.payload;
      })
      .addCase(getAssetProcesses.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get company assets associated with Business Process -- */
      .addCase(getAssets.pending, (state) => {
        state.isAssetsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.isAssetsLoading = false;
        state.errorMsg = "";
        state.assets = action.payload;
      })
      .addCase(getAssets.rejected, (state, action) => {
        state.isAssetsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get get Default list of Assets -- */
      .addCase(getDefaultAssets.pending, (state) => {
        state.defaultAssetsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getDefaultAssets.fulfilled, (state, action) => {
        state.defaultAssetsLoading = false;
        state.errorMsg = "";
        state.defaultAssets = action.payload;
      })
      .addCase(getDefaultAssets.rejected, (state, action) => {
        state.defaultAssetsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- get Default list of Sub Assets -- */
      .addCase(getDefaultSubAssets.pending, (state) => {
        state.defaultSubAssetsLoading = true;
        state.errorMsg = "";
        state.defaultSubAssets = [];
      })
      .addCase(getDefaultSubAssets.fulfilled, (state, action) => {
        state.defaultSubAssetsLoading = false;
        state.errorMsg = "";
        state.defaultSubAssets = action.payload;
      })
      .addCase(getDefaultSubAssets.rejected, (state, action) => {
        state.defaultSubAssetsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- add an asset to a company -- */
      .addCase(addCompanyAsset.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(addCompanyAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(addCompanyAsset.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- update an asset to a company -- */
      .addCase(updateCompanyAsset.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateCompanyAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateCompanyAsset.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Delete an asset to a company -- */
      .addCase(deleteCompanyAsset.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(deleteCompanyAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(deleteCompanyAsset.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getAssetProcesses = createAsyncThunk(
  "asset/get_asset_processes",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/asset-processes/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getAssets = createAsyncThunk(
  "asset/get_assets",
  async ({ processId, nextUrl }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/asset-processes/${processId}/assets/?limit=30`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getDefaultAssets = createAsyncThunk(
  "asset/get_default_assets",
  async (nextUrl, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let url = `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/assets/?limit=20`;
      if (nextUrl) {
        url = nextUrl;
      }

      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getDefaultSubAssets = createAsyncThunk(
  "asset/get_default_sub_assets",
  async (assetId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/assets/${assetId}/sub-assets/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const addCompanyAsset = createAsyncThunk(
  "asset/add_company_asset",
  async (assetData, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/add-company-asset/`,
        assetData,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateCompanyAsset = createAsyncThunk(
  "asset/update_company_asset",
  async ({ assetId, assetData }, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company-assets/${assetId}/`,
        assetData,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const deleteCompanyAsset = createAsyncThunk(
  "asset/deleteCompanyAsset",
  async (assetId, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/v1/user-onboarding/company-assets/${assetId}/`,
        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState, resetAssets, resetDefaultAssets } =
  assetSlice.actions;

export default assetSlice.reducer;
