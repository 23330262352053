import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: false,
  pricingData: null,
  errorMsg: "",
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Payment -- */
      .addCase(fetchSubscription.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.pricingData = action.payload;
      })
      .addCase(fetchSubscription.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- payment -- */
export const fetchSubscription = createAsyncThunk(
  "payment/fetchPayment",
  async (employeeRange, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/payments/pricing-table/`,
        employeeRange,
        { withCredentials: true },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = paymentSlice.actions;
export default paymentSlice.reducer;
