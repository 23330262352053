import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setLoggedIn, setReloading } from "../login/authSlice";

const initialState = {
  loading: false,
  errorMsg: "",
  auth2FA: {},
  userEmail: "",
  invitationData: false,
};

const signUpSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- SignUp -- */
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.invitationData = action.payload.invitationData;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;

        state.errorMsg = action.payload.error;
      })

      /* --- Password OTP verification -- */
      .addCase(otpVerification.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(otpVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";

        const authInfo = {
          qrCode: action.payload.qrCode,
        };
        state.auth2FA = authInfo;
      })
      .addCase(otpVerification.rejected, (state, action) => {
        state.loading = false;

        state.errorMsg = action.payload.error;
      })

      /* --- 2FA verification -- */
      .addCase(verification2FA.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(verification2FA.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";

        // localStorage.removeItem("email");
      })
      .addCase(verification2FA.rejected, (state, action) => {
        state.loading = false;

        state.errorMsg = action.payload.error;
      });
  },
});

export const signUp = createAsyncThunk(
  "auth/signup",
  async (signUpData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/register/`,
        signUpData,
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Password OTP Confirmation -- */
export const otpVerification = createAsyncThunk(
  "signUpOtpVerify/signUpOpt",
  async (signUpOTP, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/verify-email/`,
        signUpOTP,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- 2FA Confirmation -- */
export const verification2FA = createAsyncThunk(
  "signUp2FA/signUp2FA",
  async (signUp2FAOTP, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/verify-otp/`,
        signUp2FAOTP,
        { withCredentials: true },
      );

      const data = await response.data;
      dispatch(setLoggedIn(true));
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState, setUserEmail } = signUpSlice.actions;
export default signUpSlice.reducer;
