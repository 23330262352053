import { Rings } from "react-loader-spinner";
import { useTheme } from "@mui/material";

function Loader({ sx, wrapperStyle, wrapperClass }) {
  const theme = useTheme();

  return (
    <div className="loader-overlay" style={sx}>
      <Rings
        height="120"
        width="120"
        color={theme.palette.midGreen.main}
        radius="10"
        wrapperStyle={wrapperStyle || {}}
        wrapperClass={wrapperClass || ""}
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
}

export default Loader;
