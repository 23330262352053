import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: false,
  errorMsg: "",
  otp: "",
  storeEmail: "",
};

const resetSlice = createSlice({
  name: "reset",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
    setOtp(state, action) {
      state.otp = action.payload;
    },
    setStoreEmail: (state, action) => {
      state.storeEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Validate user email -- */
      .addCase(resetPasswordVerify.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(resetPasswordVerify.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(resetPasswordVerify.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- OTP Confirmation -- */
      .addCase(verifyEmail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- New Password Confirmation -- */
      .addCase(resetPasswordConfirm.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(resetPasswordConfirm.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(resetPasswordConfirm.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Validate user email -- */
export const resetPasswordVerify = createAsyncThunk(
  "auth/resetPasswordVerify",
  async (userEmail, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/reset-password-verify/`,
        userEmail,
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- OTP Confirmation -- */
export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (emailConfirmation, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/verify-email/`,
        emailConfirmation,
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- New Password Confirmation -- */
export const resetPasswordConfirm = createAsyncThunk(
  "auth/resetPasswordConfirm",
  async (userNewCredentials, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/v1/accounts/reset-password-confirm/`,
        userNewCredentials,
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState, setOtp, setStoreEmail } = resetSlice.actions;
export default resetSlice.reducer;
